import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { DecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { parseAndCreateZippableFileName } from '@standardnotes/utils';
export class FileExportNode extends DecoratorBlockNode {
    static getType() {
        return 'file-export';
    }
    constructor(name, mimeType, format, key) {
        super(format, key);
        this.__name = name;
        this.__mimeType = mimeType;
    }
    static clone(node) {
        return new FileExportNode(node.__name, node.__mimeType, node.__format, node.__key);
    }
    static importJSON(serializedNode) {
        const node = $createFileExportNode(serializedNode.name, serializedNode.mimeType).updateFromJSON(serializedNode);
        return node;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            name: this.__name,
            mimeType: this.__mimeType,
        };
    }
    getZippableFileName() {
        return parseAndCreateZippableFileName(this.__name);
    }
    getTextContent() {
        return `${this.__mimeType.startsWith('image/') ? '!' : ''}[${this.__name}](./${this.getZippableFileName()})`;
    }
    exportDOM() {
        const src = `./${this.getZippableFileName()}`;
        if (this.__mimeType.startsWith('image/')) {
            const img = document.createElement('img');
            img.setAttribute('src', src);
            return { element: img };
        }
        else if (this.__mimeType.startsWith('audio')) {
            const audio = document.createElement('audio');
            audio.setAttribute('controls', '');
            const source = document.createElement('source');
            source.setAttribute('src', src);
            source.setAttribute('type', this.__mimeType);
            audio.appendChild(source);
            return { element: audio };
        }
        else if (this.__mimeType.startsWith('video')) {
            const video = document.createElement('video');
            video.setAttribute('controls', '');
            const source = document.createElement('source');
            source.setAttribute('src', src);
            source.setAttribute('type', this.__mimeType);
            video.appendChild(source);
            return { element: video };
        }
        const object = document.createElement('object');
        object.setAttribute('data', src);
        object.setAttribute('type', this.__mimeType);
        return { element: object };
    }
    decorate() {
        // Doesn't need to actually render anything since this is only used for export
        return _jsx(_Fragment, {});
    }
}
export function $createFileExportNode(name, mimeType) {
    return new FileExportNode(name, mimeType);
}
