import { jsx as _jsx } from "react/jsx-runtime";
import { DecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import RemoteImageComponent from './RemoteImageComponent';
export class RemoteImageNode extends DecoratorBlockNode {
    static getType() {
        return 'unencrypted-image';
    }
    constructor(src, alt, format, key) {
        super(format, key);
        this.__src = src;
        this.__alt = alt;
    }
    static clone(node) {
        return new RemoteImageNode(node.__src, node.__alt, node.__format, node.__key);
    }
    static importJSON(serializedNode) {
        return $createRemoteImageNode(serializedNode.src, serializedNode.alt).updateFromJSON(serializedNode);
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            src: this.__src,
            alt: this.__alt,
        };
    }
    static importDOM() {
        return {
            img: (domNode) => {
                if (domNode.tagName !== 'IMG') {
                    return null;
                }
                return {
                    conversion: () => {
                        if (!(domNode instanceof HTMLImageElement)) {
                            return null;
                        }
                        return {
                            node: $createRemoteImageNode(domNode.currentSrc || domNode.src, domNode.alt),
                        };
                    },
                    priority: 2,
                };
            },
        };
    }
    exportDOM() {
        const element = document.createElement('img');
        if (this.__alt) {
            element.setAttribute('alt', this.__alt);
        }
        element.setAttribute('src', this.__src);
        return { element };
    }
    getTextContent() {
        return `![${this.__alt || 'image'}](${this.__src})`;
    }
    decorate(_editor, config) {
        const embedBlockTheme = config.theme.embedBlock || {};
        const className = {
            base: embedBlockTheme.base || '',
            focus: embedBlockTheme.focus || '',
        };
        return (_jsx(RemoteImageComponent, { className: className, format: this.__format, nodeKey: this.getKey(), node: this, src: this.__src, alt: this.__alt }));
    }
}
export function $isRemoteImageNode(node) {
    return node instanceof RemoteImageNode;
}
export function $createRemoteImageNode(src, alt) {
    return new RemoteImageNode(src, alt);
}
