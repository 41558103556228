import { jsx as _jsx } from "react/jsx-runtime";
import { DecoratorNode } from 'lexical';
import { $createBubbleNode, convertToBubbleElement } from './BubbleUtils';
import { BubbleComponent } from './BubbleComponent';
export class BubbleNode extends DecoratorNode {
    static getType() {
        return 'snbubble';
    }
    static clone(node) {
        return new BubbleNode(node.__id, node.__key);
    }
    static importJSON(serializedNode) {
        return $createBubbleNode(serializedNode.itemUuid).updateFromJSON(serializedNode);
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            itemUuid: this.getId(),
        };
    }
    static importDOM() {
        return {
            div: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-item-uuid')) {
                    return null;
                }
                return {
                    conversion: convertToBubbleElement,
                    priority: 2,
                };
            },
        };
    }
    createDOM() {
        return document.createElement('span');
    }
    exportDOM() {
        const element = document.createElement('span');
        element.setAttribute('data-lexical-item-uuid', this.__id);
        const text = document.createTextNode(this.getTextContent());
        element.append(text);
        return { element };
    }
    updateDOM() {
        return false;
    }
    constructor(id, key) {
        super(key);
        this.__id = id;
    }
    getId() {
        return this.__id;
    }
    getTextContent(_includeInert, _includeDirectionless) {
        return `[Item: ${this.__id}]`;
    }
    decorate(_editor) {
        return _jsx(BubbleComponent, { node: this, itemUuid: this.__id });
    }
}
