import { ELEMENT_TRANSFORMERS, TEXT_FORMAT_TRANSFORMERS, TEXT_MATCH_TRANSFORMERS, MULTILINE_ELEMENT_TRANSFORMERS, } from '@lexical/markdown';
import { $isParagraphNode, $isTextNode } from 'lexical';
function indexBy(list, callback) {
    const index = {};
    for (const item of list) {
        const key = callback(item);
        if (index[key]) {
            index[key].push(item);
        }
        else {
            index[key] = [item];
        }
    }
    return index;
}
export function transformersByType(transformers) {
    const byType = indexBy(transformers, (t) => t.type);
    return {
        element: (byType.element || []),
        multilineElement: (byType['multiline-element'] || []),
        textFormat: (byType['text-format'] || []),
        textMatch: (byType['text-match'] || []),
    };
}
const MARKDOWN_EMPTY_LINE_REG_EXP = /^\s{0,3}$/;
export function isEmptyParagraph(node) {
    if (!$isParagraphNode(node)) {
        return false;
    }
    const firstChild = node.getFirstChild();
    return (firstChild == null ||
        (node.getChildrenSize() === 1 &&
            $isTextNode(firstChild) &&
            MARKDOWN_EMPTY_LINE_REG_EXP.test(firstChild.getTextContent())));
}
export const TRANSFORMERS = [
    ...ELEMENT_TRANSFORMERS,
    ...MULTILINE_ELEMENT_TRANSFORMERS,
    ...TEXT_FORMAT_TRANSFORMERS,
    ...TEXT_MATCH_TRANSFORMERS,
];
